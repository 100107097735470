import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { constants } from './constants';

const resources = {
  en: {
    translation: {
      back: 'Back',
      administration: 'Administration',
      information: 'Information',
      replayGame: '🕊️ Retry delivery',
      noGuestSpecified: 'No guest specified',
      guestNotFound: 'Guest with this ID {{id}} not found',
      brideAndGroomNames: `${constants.brideName} and ${constants.groomName}`,
      when: 'When',
      where: 'Where',
      map: 'map',
      leaderboard: 'Leaderboard',
      pointsAndPlays: '{{points}} points ({{plays}} plays)',

      allergiesPlaceholder: 'Example: Peanuts and milk',

      willYouBeThere: 'Will you be there?',
      answerYes: 'I will be there!',
      answerNo: 'Unfortunately can\'t come',
      answerMaybe: 'Not sure yet',
      notAnswered: 'Unanswered',
      fillInYourAllergiesOrDiet: 'Do you have any allergies/special diet?',
      sincerely: 'Sincerely',
      sendAnswer: 'Send answer',

      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      latitude: 'Latitude',
      longitude: 'Longitude',
      answer: 'Answer',
      allergies: 'Allergies',
      highscore: 'Highscore',

      admin: {
        addAuthInUrl: 'Add ?auth=<key> in url for authorization.',
        listGuests: 'List guests',
        addGuest: 'Add guest',
        addGuestTitle: 'Add new guest',
        confirmGuestDeletion: 'Are you sure you want to delete {{name}}?',
        copyEmails: 'Copy emails'
      }
    }
  },
  no: {
    translation: {
      back: 'Tilbake',
      administration: 'Administrering',
      information: 'Informasjon',
      replayGame: '🕊️ Restart levering',
      noGuestSpecified: 'Gjest ikke spesifisert',
      guestNotFound: 'Fant ikke gjest med ID {{id}}',
      brideAndGroomNames: `${constants.brideName} og ${constants.groomName}`,
      when: 'Når',
      where: 'Hvor',
      map: 'kart',
      leaderboard: 'Toppliste',
      pointsAndPlays: '{{points}} poeng ({{plays}} forsøk)',

      allergiesPlaceholder: 'Eksempel: Peanøtter og melk',

      willYouBeThere: 'Svar',
      answerYes: 'Jeg kommer!',
      answerNo: 'Jeg kan dessverre ikke',
      answerMaybe: 'Foreløpig usikker',
      notAnswered: 'Ikke svart',
      fillInYourAllergiesOrDiet: 'Fyll inn evt. allergier/diett',
      sincerely: 'Med vennlig hilsen',
      sendAnswer: 'Send svar',

      firstName: 'Fornavn',
      lastName: 'Etternavn',
      email: 'E-post',
      latitude: 'Breddegrad',
      longitude: 'Lengdegrad',
      answer: 'Svar',
      allergies: 'Allergier',
      highscore: 'Toppscore',

      admin: {
        addAuthInUrl: 'Legg til ?auth=<key> i url for autorisasjon.',
        listGuests: 'Vis gjester',
        addGuest: 'Legg til gjest',
        addGuestTitle: 'Legg til gjest',
        confirmGuestDeletion: 'Er du sikker på at du ønsker å slette {{name}}?',
        copyEmails: 'Kopier epost-adresser'
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'no',
    interpolation: {
      escapeValue: false
    },
    fallbackLng: 'en'
  }, (err, t) =>   console.info('i18next.language', i18n.language)  );

export default i18n;