import React, { ReactElement, useState, useEffect } from "react";
import { BirdGame } from "../components/BirdGame";
import { Guest } from "../models/Guest";
import { useQuery } from "../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../utils/baseUrl";

export const GamePage = (): ReactElement => {
  const query = useQuery();
  const [guest, setGuest] = useState<Guest>();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const { t } = useTranslation();

  const guestId = query.get("guestId");

  if (!guestId) {
    return <>{t('noGuestSpecified')}</>
  }

  useEffect(() => {
    (async () => {
      const data = await fetch(`${baseUrl}/guest/${guestId}`);
      const json = await data.json();
      if (!data.ok) {
        setHasLoaded(true);
        return;
      }

      setGuest(json);
      setHasLoaded(true);
    })();
  }, [])

  if (hasLoaded && !guest) {
    return <>{t('guestNotFound', { id: guestId })}</>
  }

  if (!guest) {
    return <></>
  }

  return (
    <>
      <BirdGame guest={guest} />
      <div className="info-button-container">
        <a href={`/?guestId=${guest.guestId}`}>
          <button>{t('back')}</button>
        </a>
      </div>
    </>
  );
};
