export interface Guest {
  guestId: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;

  latitude: number;
  longitude: number;

  answer: Answer;
  allergies: string;
  highscore: number;
  playCount: number;
}

export enum Answer {
  Unanswered = 0,
  Yes = 1,
  No = 2,
  Maybe = 3
}