import React, { ReactElement, useState, useEffect } from "react";
import { useQuery } from "../hooks/useQuery";
import { Card } from "../components/Card";
import { ResponseEnvelope } from "../components/ResponseEnvelope";
import { Guest } from "../models/Guest";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../utils/baseUrl";

export const InformationPage = (): ReactElement => {
  const query = useQuery();
  const [isResponseOpen, setResponseOpen] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [guest, setGuest] = useState<Guest>();
  const { t } = useTranslation();

  const guestId = query.get("guestId");

  if (!guestId) {
    return <>{t('noGuestSpecified')}</>
  }

  useEffect(() => {
    (async () => {
      const data = await fetch(`${baseUrl}/guest/${guestId}`);
      if (!data.ok) {
        setHasLoaded(true);
        return;
      }

      const json = await data.json();
      setGuest(json);
      setHasLoaded(true);
    })();
  }, [])

  if (hasLoaded && !guest) {
    return <>{t('guestNotFound', { id: guestId })}</>
  }

  if (!guest) {
    return <></>
  }

  return (
    <div className="page-container">
      <Card guest={guest} isFlipped={true} openResponseCard={() => setResponseOpen(true)} />
      {isResponseOpen && (<ResponseEnvelope guest={guest} responseSent={() => setResponseOpen(false)} close={() => setResponseOpen(false)} />)}
    </div>
  );
};
