import React, { ReactElement, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../utils/baseUrl";

interface ScoreDto {
  name: string;
  highscore: number;
  playCount: number;
}

interface HighscoreListProps {
  nickname: string;
}

export const HighscoreList = ( { nickname }: HighscoreListProps): ReactElement => {
  const [highscores, setHighscores] = useState<ScoreDto[]>();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const data = await fetch(`${baseUrl}/game/scores`);
      const json = await data.json();
      if (!data.ok) {
        return;
      }

      setHighscores(json);
    })();
  }, [])

  return (
    <div>
      <h1>{t('leaderboard')}</h1>

      <ol>
        {highscores?.filter(s => s.playCount > 0).map(s => {
          return (
            <li key={s.name} className={(nickname == s.name ? "font-bold" : "")}>{s.name} - {t('pointsAndPlays', { points: s.highscore, plays: s.playCount })}</li>
          )
        })}
      </ol>
    </div>
  );
};