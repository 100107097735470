import React, { ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import './Admin.css';
import { baseUrl } from "../../utils/baseUrl";

export const AddGuestPage = (): ReactElement => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const navigate = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();

  const auth = query.get("auth");

  if (!auth) {
    return <>{t('admin.addAuthInUrl')}</>
  }

  const addGuest = async (e: any) => {
    e.preventDefault();

    const testUser = {
      firstName,
      lastName,
      email,
      latitude,
      longitude
    }

    const response = await fetch(`${baseUrl}/admin/guest`, {
      method: 'POST',
      headers: {
        'Authorization': auth,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(testUser)
    })

    if (!response.ok) {
      alert(response.status);
      return;
    }

    navigate(`/admin/guests?auth=${auth}`);
  }

  return (
    <div className="admin-container">
      <a href={`/admin?auth=${auth}`}>{t('back')}</a>
      <h1>{t('admin.addGuestTitle')}</h1>

      <form onSubmit={addGuest}>

        <div>
          <label>{t('firstName')}:</label>
          <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
        </div>

        <div>
          <label>{t('lastName')}:</label>
          <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />

        </div>

        <div>
          <label>{t('email')}:</label>
          <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
        </div>

        <div>
          <label>{t('latitude')}:</label>
          <input type="number" value={latitude} onChange={e => setLatitude(Number.parseFloat(e.target.value))} />
        </div>

        <div>
          <label>{t('longitude')}:</label>
          <input type="number" value={longitude} onChange={e => setLongitude(Number.parseFloat(e.target.value))} />
        </div>

        <div>
          <button type="submit">{t('admin.addGuest')}</button>
        </div>
      </form>
    </div>
  );
};
