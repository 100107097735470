class Utils {
    static createRandomCircumferencePoint = (middle, radius) => {
        var angle = Math.random() * Math.PI * 2;
        const x = middle[0] + Math.cos(angle) * radius;
        const y = middle[1] + Math.sin(angle) * radius;

        return [x, y];
    }

    static toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }

    static calculateDistance = (coord1, coord2) => {
        const R = 6371e3; // Earth's radius in meters
        const lat1Rad = Utils.toRadians(coord1[0]);
        const lat2Rad = Utils.toRadians(coord2[0]);
        const deltaLat = Utils.toRadians(coord2[0] - coord1[0]);
        const deltaLon = Utils.toRadians(coord2[1] - coord1[1]);

        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;

        return distance;
    }


    static calculateAngle = (coord1, coord2) => {
        const deltaY = coord2[1] - coord1[1];
        const deltaX = coord2[0] - coord1[0];
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        return angle;
    }

    static getQueryParam = (param) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(param);
    }

    static getRandomLatLngOverLand = () => {
        // Bounding boxes for land areas
        const landBoundingBoxes = [
            { minLat: -60, maxLat: 85, minLng: -180, maxLng: 180 }, // Most of the world
            { minLat: 60, maxLat: 71, minLng: -30, maxLng: 60 }, // Northern Europe
            { minLat: -56, maxLat: -50, minLng: -74, maxLng: -65 }, // Southern tip of South America
        ];

        // Select a random bounding box
        const selectedBox = landBoundingBoxes[Math.floor(Math.random() * landBoundingBoxes.length)];

        // Generate random latitude and longitude within the selected bounding box
        const lat = selectedBox.minLat + Math.random() * (selectedBox.maxLat - selectedBox.minLat);
        const lng = selectedBox.minLng + Math.random() * (selectedBox.maxLng - selectedBox.minLng);

        return [lng, lat];
    }
}

class Obstacle {
    constructor(feature, center) {
        this.start = Date.now();
        this.feature = feature;
        this.geometry = this.feature.getGeometry();

        const endCoordinates = [
            center[0] + 0.1 * (Math.random() - 0.5),
            center[1] + 0.1 * (Math.random() - 0.5)
        ];

        this.startPosition = ol.proj.fromLonLat(center);
        this.endPosition = ol.proj.fromLonLat(endCoordinates);

        this.style = new ol.style.Style({
            image: new ol.style.Icon({
                src: 'img/drone.png',
                scale: 0.5,
                rotation: Utils.calculateAngle(this.startPosition, this.endPosition) * (Math.PI / 180)
            }),
        })

        this.feature.setStyle(this.style);

        this.duration = 10000 + Math.random() * 5000;
    }

    update() {
        this.fraction = (Date.now() - this.start) / this.duration;
        var position = [
            this.startPosition[0] + (this.endPosition[0] - this.startPosition[0]) * this.fraction,
            this.startPosition[1] + (this.endPosition[1] - this.startPosition[1]) * this.fraction
        ];

        this.geometry.setCoordinates(position);
    }

    getCoordinates() {
        return this.geometry.getCoordinates();
    }

    removeFromMap(source) {
        source.removeFeature(this.feature);
    }
}

class Map {
    map = null;

    #targetSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" width="400" height="400">
    <circle cx="200" cy="200" r="199" fill="rgba(0, 255, 0, 0.1)" stroke="black" stroke-width="2"/>
    <circle cx="200" cy="200" r="150" fill="rgba(0, 255, 0, 0.2)" stroke="black" stroke-width="2"/>
    <circle cx="200" cy="200" r="100" fill="rgba(0, 255, 0, 0.3)" stroke="black" stroke-width="2"/>
    <circle cx="200" cy="200" r="50" fill="rgba(0, 255, 0, 0.35)" stroke="black" stroke-width="2"/>
    <circle cx="200" cy="200" r="5" fill="red" />
    </svg>`;

    constructor(container, startCoordinates, targetCoordinates) {
        const targetFeature = new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.fromLonLat(targetCoordinates)),
        });

        const targetStyle = new ol.style.Style({
            image: new ol.style.Icon({
                src: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.#targetSVG),
                imgSize: [400, 400],
                scale: 1,
            }),
        });

        targetFeature.setStyle(targetStyle);

        const targetSource = new ol.source.Vector({
            features: [targetFeature],
        });

        const targetLayer = new ol.layer.Vector({
            source: targetSource,
        });

        this.obstaclesLayer = new ol.layer.Vector({
            source: new ol.source.Vector({
                features: []
            })
        });

        this.obstaclesLayer.setProperties({
            className: 'obstacle'
        });

        const defaultControls = ol.control.defaults({
            zoom: false
        });

        this.map = new ol.Map({
            target: container,
            layers: [
                new ol.layer.Tile({
                    source: new ol.source.OSM()
                }),
                this.obstaclesLayer,
                targetLayer
            ],
            view: new ol.View({
                center: ol.proj.fromLonLat(startCoordinates),
                zoom: 15
            }),
            interactions: [],
            controls: defaultControls
        });
    }

    getCenter() {
        return this.map.getView().getCenter();
    }

    setCenter(coordinates) {
        this.map.getView().setCenter(coordinates);
    }

    getResolution() {
        return this.map.getView().getResolution();
    }

    addObstacle(center) {
        const feature = new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.fromLonLat(center)),
        });

        this.obstaclesLayer.getSource().addFeature(feature);

        return new Obstacle(feature, center);
    }

    getElementPositionInMapCoordinates(htmlElement) {
        const elementRect = htmlElement.getBoundingClientRect();
        const mapView = this.map.getView();

        const elementX = elementRect.left + elementRect.width / 2;
        const elementY = elementRect.top + elementRect.height / 2;

        return this.map.getCoordinateFromPixel([elementX, elementY]);
    }
}

class Bird {
    #frameWidth = 129;

    #currentRotation = 90;
    #currentFrameIndex = 0;
    #birdIndex = 0;

    htmlElement = null;
    #htmlShadowElement = null;

    constructor(parent) {
        this.htmlElement = document.createElement("div");
        this.htmlElement.className = "bird";
        this.htmlElement.style.backgroundImage = "url(img/bird-sprite.png)";
        
        this.#htmlShadowElement = document.createElement("div");
        this.#htmlShadowElement.className = "bird-shadow";
        this.#htmlShadowElement.style.backgroundImage = "url(img/bird-sprite-shadow.png)";

        parent.appendChild(this.htmlElement);
        parent.appendChild(this.#htmlShadowElement);
    }

    draw() {
        this.#birdIndex += 1;
        const frameCount = 8;
        const frameDuration = 100; // in milliseconds
        const timePerFrame = frameDuration / frameCount;

        if (this.#birdIndex > timePerFrame) {
            this.#birdIndex = 0;
            this.#currentFrameIndex = (this.#currentFrameIndex + 1) % frameCount;
            this.htmlElement.style.backgroundPosition = `-${this.#currentFrameIndex * this.#frameWidth}px 0`;
            this.#htmlShadowElement.style.backgroundPosition = `-${this.#currentFrameIndex * this.#frameWidth}px 0`;
        }

        this.htmlElement.style.transform = `translate(-50%, -50%) rotateZ(${this.#currentRotation - 90}deg)`;
        this.#htmlShadowElement.style.transform = `translate(-40%, -40%) rotateZ(${this.#currentRotation - 90}deg)`;
    }

    addRotation(rotation) {
        this.#currentRotation += rotation;
    }

    setRotation(rotation) {
        this.#currentRotation = rotation;
    }

    getRotation() {
        return this.#currentRotation;
    }

    collidesWithObstacle(obstacleCoordinates, birdCoordinates, obstacleRadius, birdRadius) {
        const dx = obstacleCoordinates[0] - birdCoordinates[0];
        const dy = obstacleCoordinates[1] - birdCoordinates[1];
        const distance = Math.sqrt(dx * dx + dy * dy);

        return distance < (obstacleRadius + birdRadius);
    }
}

export class Game {
    rotationSpeed = 250;
    initialMovementSpeed = 300;
    maxMovementSpeed = 1000;
    deltaTime = 1 / 120;
    frameDelayMs = 0;

    targetCoordinates = null;
    targetPosition = null;
    bird = null;
    map = null;
    distanceElement = null;
    arrow = null;
    obstacles = [];

    invitationDropped = false;
    score = 0;
    gameInterval;
    rotation = 0;
    movementSpeed = 0;
    flipBird = false;
    speedDecreaseTimeout = null;
    obstaclesHit = 0;

    startTime;
    isMobileDevice = false;

    callbacks = []

    constructor(gameContainer, targetLatitude, targetLongitude) {
        this.gameContainer = gameContainer;
        const valid = targetLatitude && targetLongitude && !isNaN(targetLatitude) && !isNaN(targetLongitude);

        this.targetCoordinates = (valid) ? [targetLongitude, targetLatitude] : Utils.getRandomLatLngOverLand();

        const startCoordinates = Utils.createRandomCircumferencePoint(this.targetCoordinates, 0.2);
        this.bird = new Bird(gameContainer);
        this.map = new Map(gameContainer, startCoordinates, this.targetCoordinates);

        this.arrow = document.createElement('div');
        this.arrow.className = "arrow";
        this.arrow.style.backgroundImage = "url(img/arrow.png)"

        this.distanceElement = document.createElement('distance');
        this.distanceElement.className = "distance";

        gameContainer.appendChild(this.arrow);
        gameContainer.appendChild(this.distanceElement);

        this.targetPosition = ol.proj.fromLonLat(this.targetCoordinates);
        this.frameDelayMs = Math.floor(this.deltaTime * 1000); // 60fps
        this.movementSpeed = this.initialMovementSpeed;

        this.isMobileDevice = window.DeviceOrientationEvent && 'ontouchstart' in window;

        if (!this.isMobileDevice) {
            window.addEventListener('keydown', this.#handleKeyDown);
            window.addEventListener('keyup', this.#handleKeyUp);
        }
    }

    #dropInvitation = () => {
        if (this.invitationDropped) {
            this.callbacks.forEach(callback => callback(0, 0));
            return;
        }

        this.invitationDropped = true;
        const mapCenter = this.map.getCenter();
        const lonLat = ol.proj.toLonLat(mapCenter);
        const distance = Utils.calculateDistance(lonLat, this.targetCoordinates);

        const durationMs = Date.now() - this.startTime;

        const distanceScore = Math.pow(Math.max(0, 500 - distance), 1.1);
        const obstaclesHitPenalty = this.obstaclesHit * 25;

        const finalScore = distanceScore - (durationMs * 2 / 1000) - obstaclesHitPenalty;

        const scoreRounded = Math.round(Math.max(0, finalScore));

        console.log('duration', durationMs, 'distance', distance, 'obstacle penalty', obstaclesHitPenalty, 'score', scoreRounded);

        this.callbacks.forEach(callback => callback(distance, scoreRounded));
    }

    onInvitationDropped(callback) {
        this.callbacks.push(callback);
      }
    
    #handleKeyDown = (event) => {
        if (event.code === 'ArrowLeft' || event.code === 'ArrowRight' || event.code === 'Enter'
                || event.code === 'ArrowUp' || event.code === 'ArrowDown' || event.code === 'Space') {
            event.preventDefault();
        }

        if (event.code === 'ArrowLeft') {
            this.rotation = -1;
        } else if (event.code === 'ArrowRight') {
            this.rotation = 1;
        }
        else if (event.code === 'Enter' && !event.repeat) {
            this.flipBird = true;
        }
        else if (event.code === 'Space') {
            this.#dropInvitation();
        }

        if (this.speedDecreaseTimeout !== null) {
            return;
        }

        if (event.code === 'ArrowUp') {
            this.movementSpeed = this.initialMovementSpeed * 3;
        }
        else if (event.code === 'ArrowDown') {
            this.movementSpeed = this.initialMovementSpeed * 0.3;
        }
    }

    #handleKeyUp = (event) => {
        if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
            this.rotation = 0;
        }

        if (this.speedDecreaseTimeout !== null) {
            return;
        }

        if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
            this.movementSpeed = this.initialMovementSpeed;
        }
    }

    #handleTouchStart = (event) => {
        this.#dropInvitation();
    }

    startBeta = null;
    startGamma = null;
    mobileDeltaX = 0;
    mobileDeltaY = 0;
    mobileAngleDegrees = 0;

    #handleDeviceOrientation = (event) => {
        let { beta, gamma } = event;

        if (this.startBeta === null) {
            this.startBeta = beta;
            this.startGamma = gamma;
        }

        const deltaX = -(gamma - this.startGamma) * 0.001;
        const deltaY = (beta - this.startBeta) * 0.001;

        this.mobileDeltaX = deltaX;
        this.mobileDeltaY = deltaY;

        const angleRadians = Math.atan2(this.mobileDeltaY, this.mobileDeltaX);
        this.mobileAngleDegrees = -angleRadians * (180 / Math.PI);
    }
    
    startGameLoop = (el) => {
        if (this.isMobileDevice) {
            if (typeof DeviceOrientationEvent.requestPermission === 'function') {
                // Request permission to access the device's orientation data
                DeviceOrientationEvent.requestPermission().then(permissionState => {
                    console.log(permissionState)
                    if (permissionState === 'granted') {
                        this.gameContainer.addEventListener('touchstart', this.#handleTouchStart);
                        window.addEventListener('deviceorientation', this.#handleDeviceOrientation, true);
                    } else {
                        alert("Permission to accelerometer not granted. Please grant access or open on your computer instead.");
                    }
                })
                    .catch(error => {
                        alert(error);
                    });
            }
            else {
                // The browser doesn't support requesting permission, so just add the event listener
                this.gameContainer.addEventListener('touchstart', this.#handleTouchStart);
                window.addEventListener('deviceorientation', this.#handleDeviceOrientation, true);
            }    
        }

        this.startTime = Date.now();

        this.gameInterval = setInterval(() => {
            const mapCenter = this.map.getCenter();
            const lonLat = ol.proj.toLonLat(mapCenter);

            if (Math.random() > 0.6) {
                const pos = Utils.createRandomCircumferencePoint(lonLat, 0.06);
                this.obstacles.push(this.map.addObstacle(pos));
            }

            if (this.isMobileDevice) {
                this.bird.setRotation(this.mobileAngleDegrees);
                this.map.setCenter([mapCenter[0] - this.mobileDeltaX * this.movementSpeed, mapCenter[1] - this.mobileDeltaY * this.movementSpeed]);
            }
            else {
                if (this.flipBird) {
                    this.bird.addRotation(180);
                    this.flipBird = false;
                }
                else {
                    this.bird.addRotation(this.rotation * this.rotationSpeed * this.deltaTime)
                }
    
                const currentBirdRotation = this.bird.getRotation();
                const resolution = this.map.getResolution();
                const radians = -currentBirdRotation * (Math.PI / 180);
    
                const deltaX = resolution * Math.cos(radians) * this.movementSpeed * this.deltaTime;
                const deltaY = resolution * Math.sin(radians) * this.movementSpeed * this.deltaTime;
                this.map.setCenter([mapCenter[0] - deltaX, mapCenter[1] - deltaY]);    
            }

            const angle = Utils.calculateAngle(mapCenter, this.targetPosition);

            this.arrow.style.transform = `translate(-50%, -50%) rotate(${angle * -1}deg)`;

            this.bird.draw();

            for (let i = this.obstacles.length - 1; i >= 0; i--) {
                const obstacle = this.obstacles[i];
                obstacle.update();

                if (this.bird.collidesWithObstacle(obstacle.getCoordinates(), mapCenter, 50, 150)) {
                    if (this.speedDecreaseTimeout == null) {
                        this.movementSpeed = this.initialMovementSpeed * 0.3;
                        this.rotation = 0;

                        this.speedDecreaseTimeout = setTimeout(() => {
                            this.movementSpeed = this.initialMovementSpeed;
                            this.speedDecreaseTimeout = null;
                            this.obstaclesHit += 1;
                        }, 2000)
                    }
                }

                if (obstacle.fraction >= 1) {
                    obstacle.removeFromMap(this.map.obstaclesLayer.getSource());
                    this.obstacles.splice(i, 1);
                }
            }

            const distance = Utils.calculateDistance(lonLat, this.targetCoordinates);
            this.distanceElement.innerText = `Distance: ${distance.toFixed(0)} m`;

        }, this.frameDelayMs);
    }

    stopGameLoop = () => {
        clearInterval(this.gameInterval);
    }
}