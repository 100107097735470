import React, { ReactElement, useEffect, useRef, useState } from "react";
import { HighscoreList } from "../HighscoreList/HighscoreList";
import { Game } from "./Game";
import "../../styles/utilities.css";
import "./BirdGame.css";
import { baseUrl } from "../../utils/baseUrl";
import { Guest } from "../../models/Guest";

type BirdGameProps = {
  guest: Guest;
}

export const BirdGame = ({ guest }: BirdGameProps): ReactElement => {
  const gameContainerRef = useRef(null);
  const game = useRef<Game>();
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    game.current = new Game(gameContainerRef.current, guest.latitude, guest.longitude);
    game.current.onInvitationDropped(postScore);

    if (!localStorage.getItem('hasSeenHelpAlert')) {
      localStorage.setItem('hasSeenHelpAlert', JSON.stringify(true));
      openHelpAlert();
    }
    
    if (!game.current.isMobileDevice) {
      startGame();
    }
  }, []);

  const startGame = () => {
    game.current!.startGameLoop();
    setIsStarted(true);
  }

  const postScore = async (distance: number, score: number) => {
    const response = await fetch(`${baseUrl}/game/${guest.guestId}/score?score=${score}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      alert(response.status);
      return;
    }

    if (distance > 500) {
      alert("Noen andre fikk tak i brevet, du må levere nærmere målet!");
      window.location.reload();
      return;
    }

    if (score <= 0) {
      alert("Du brukte for lang tid!");
      window.location.reload();
    }

    if (response.ok && score > 0) {
      alert(`Du fikk ${score} poeng. Hurra, her kommer brevet!`)
      window.location.reload();
    }
  }

  const openHelpAlert = () => {
    if (game.current?.isMobileDevice) {
      alert(`Hei, ${guest.firstName}! Det er et brev på vei til deg.\n\n Hjelp brevdua med å levere det! Følg pila og pass deg for dronene. Slipp brevet over de grønne sirkelene slik at ingen andre får tak i brevet!\n\nStyr fuglen ved å rotere på mobilen, og klikk på kartet for å slippe brevet.`);
    }
    else {
      alert(`Hei, ${guest.firstName}! Det er et brev på vei til deg.\n\n Hjelp brevdua med å levere det! Følg pila og pass deg for dronene. Slipp brevet over de grønne sirkelene slik at ingen andre får tak i brevet!\n\nStyr fuglen med piltastene, og klikk mellomromstasten for å slippe brevet.`);
    }
  }

  return (
    <div className="game-page-container">
      <div>
        <div className="game-container" ref={gameContainerRef}>
          {!isStarted && <button className="absolute inset-0 z-50" onClick={startGame}>Start</button>}
        </div>
      </div>
      <div className="highscore-list">
        <button className="mt-2" onClick={openHelpAlert}>❔ Hjelp</button>
        <HighscoreList nickname={guest.nickname} />
      </div>
    </div>
  );
};