import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { InformationPage } from "./pages/InformationPage";
import { AdminPage } from "./pages/Admin/AdminPage";
import { AddGuestPage } from "./pages/Admin/AddGuestPage";
import { ListGuestsPage } from "./pages/Admin/ListGuestsPage";
import { GamePage } from "./pages/GamePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/information",
    element: <InformationPage />
  },
  {
    path: "/delivery",
    element: <GamePage />
  },
  {
    path: "/admin",
    children: [
      {
        index: true,
        element: <AdminPage />
      },
      {
        path: "guests/add",
        element: <AddGuestPage />
      },  
      {
        path: "guests",
        element: <ListGuestsPage />
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
