import React, { ReactElement, useState, useEffect } from "react";
import { Answer, Guest } from "../../models/Guest";
import { useTranslation } from "react-i18next";
import './Admin.css';

type GuestInfoProps = {
  guest: Guest;
  deleteClicked: (guest: Guest) => Promise<void>;
}

export const GuestInfo = ({ guest, deleteClicked }: GuestInfoProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div key={guest.guestId} className="guest-item">
      <img className="guest-item-image" src={`/img/guests/${guest.guestId}.jpg`} alt="Bilde" />
      <div className="guest-name">{guest.nickname} {guest.firstName} {guest.lastName}</div>
      <div>{t('email')}: <a href={`mailto:${guest.email}`}>{guest.email}</a></div>
      <div>{t('answer')}: {guest.answer ? Answer[guest.answer] : Answer[Answer.Unanswered]}</div>
      <div>{t('allergies')}: {guest.allergies}</div>
      <div>{t('highscore')}: {guest.highscore} ({guest.playCount} plays)</div>

      <div className="guest-item-actions">
        <a className="button" target="_blank" href={`http://maps.google.com/maps?z=12&t=m&q=loc:${guest.latitude},${guest.longitude}`}>
          <button>🗺️</button>
        </a>
        <a className="button" href={`/?guestId=${guest.guestId}`}>
          <button>✉️</button>
        </a>
        <button onClick={() => deleteClicked(guest)}>X</button>
      </div>
    </div>
  );
};
