import React, { ReactElement, useState } from "react";
import "./ResponseEnvelope.css";
import { ResponseCard } from "../ResponseCard";
import { Guest } from "../../models/Guest";

type ResponseEnvelopeProps = {
  responseSent: () => void;
  close: () => void;
  guest: Guest;
}

export const ResponseEnvelope = ({ responseSent, close, guest }: ResponseEnvelopeProps): ReactElement => {
  const [isClosing, setIsClosing] = useState(false);

  const setClose = async () => {
    setIsClosing(true);

    await new Promise<void>(resolve => {
      setTimeout(() => {
        responseSent();
        resolve();
      }, 4000);
    });
  }

  return (
    <>
      <div className={"response-background " + (isClosing ? "envelope-closed" : "")}></div>
      <div className={"envelope-container " + (isClosing ? "envelope-closed" : "")}>
        <button className="close-button" onClick={() => close()}>✖️</button>
        <img className={"nyan " + (isClosing ? "nyan-active" : "")} src="./img/nyan.webp" />
        <div className="envelope-back">
        </div>
        <ResponseCard guest={guest} send={setClose} />
        <div className="envelope-front">
          <div className="envelope-flap"></div>
          <div className="envelope-right"></div>
          <div className="envelope-left"></div>
          <div className="envelope-bottom"></div>
          <div className="envelope-hack"></div>
        </div>
      </div>
    </>
  )
}