import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import "./ResponseCard.css";
import { Guest, Answer } from "../../models/Guest";
import { baseUrl } from "../../utils/baseUrl";

type ResponseCardProps = {
  send: () => Promise<void>;
  guest: Guest;
}

export const ResponseCard = ({ send, guest }: ResponseCardProps): ReactElement => {
  const [answer, setAnswer] = useState<Answer>(guest.answer ?? Answer.Unanswered);
  const [allergies, setAllergies] = useState<string>(guest.allergies);
  const [email, setEmail] = useState<string>(guest.email);
  const [isSending, setIsSending] = useState<boolean>();
  const { t } = useTranslation();

  const sendAnswer = async (e: any) => {
    e.preventDefault();

    if (answer === Answer.Unanswered) {
      alert("Du må legge inn et svar. Om du ikke vet ennå, velg foreløpig usikker 😀");
      return;
    }

    if (!email) {
      alert("Vennligst legg inn en epost-adresse slik at vi kan sende deg oppdateringer.");
      return;
    }

    if (isSending) { return; }
    setIsSending(true);

    let updatedEmail = email;
    if (email === null || email.length === 0) {
      updatedEmail = guest.email;
    }

    const updatedGuest = {
      answer,
      allergies,
      email: updatedEmail
    }

    const response = await fetch(`${baseUrl}/guest/${guest.guestId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedGuest)
    })

    if (!response.ok) {
      setIsSending(false);
      return;
    }

    await send();
    setIsSending(false);
  }

  return (
    <div className={!isSending ? "response-card-container" : "response-card-container flipped"}>
      <div className="left-side">
        <p>Om du ennå ikke vet om du kommer, velg foreløpig usikker.</p>
        <p>Husk å fylle inn e-post-adresse på høyre side, så vi kan oppdatere deg med mer informasjon.</p>

        <fieldset>
          <legend>{t('willYouBeThere')}</legend>

          <div>
            <input type="radio" id="rsvp-yes" name="rsvp" value={Answer.Yes} checked={answer === Answer.Yes} onChange={() => setAnswer(Answer.Yes)} />
            <label htmlFor="rsvp-yes">{t('answerYes')}</label>
          </div>

          <div>
            <input type="radio" id="rsvp-no" name="rsvp" value={Answer.No} checked={answer === Answer.No} onChange={() => setAnswer(Answer.No)} />
            <label htmlFor="rsvp-no">{t('answerNo')}</label>
          </div>

          <div>
            <input type="radio" id="rsvp-unsure" name="rsvp" value={Answer.Maybe} checked={answer === Answer.Maybe} onChange={() => setAnswer(Answer.Maybe)} />
            <label htmlFor="rsvp-unsure">{t('answerMaybe')}</label>
          </div>
        </fieldset>

        <div className="mt-2">
          <label htmlFor="allergies">{t('fillInYourAllergiesOrDiet')}:</label>
          <input id="allergies" className="w-100" placeholder={t('allergiesPlaceholder')} value={allergies ?? ''} onChange={e => setAllergies(e.target.value)} />
        </div>
      </div>

      <div className="right-side">
        <img className="stamp" src="./img/frimerke.jpg" alt="Frimerke" />

        <div className="spacer"></div>

        <div className="contact-lines">
          <div className="contact-line">
            <h3>{t('sincerely')}</h3>
          </div>
          <div className="contact-line">
            <span>{guest.firstName} {guest.lastName}</span>
          </div>
          <div className="contact-line">
            <input placeholder={guest.email ?? "navn@etternavn.no"} value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <button onClick={sendAnswer} disabled={isSending}>{t('sendAnswer')}</button>
        </div>
      </div>

      <img className="card-backs" src={`./img/guests/${guest.guestId}.jpg`} alt="Bilde" />
    </div>
  )
}