import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../hooks/useQuery";

export const AdminPage = (): ReactElement => {
  const { t } = useTranslation();
  const query = useQuery();
  const auth = query.get("auth");

  if (!auth) {
    return <>{t('admin.addAuthInUrl')}</>
  }

  return (
    <div className="admin-container">
      <h1>{t('administration')}</h1>

      <ul>
        <li><a href={`/admin/guests?auth=${auth}`}>{t('admin.listGuests')}</a></li>
        <li><a href={`/admin/guests/add?auth=${auth}`}>{t('admin.addGuest')}</a></li>
      </ul>
    </div>
  );
};
