import React, { ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../components/Card";
import { BirdGame } from "../components/BirdGame";
import { Guest } from "../models/Guest";
import { useQuery } from "../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../utils/baseUrl";

export const HomePage = (): ReactElement => {
  const query = useQuery();
  const [isFlipped, setIsFlipped] = useState(false);
  const [nickname, setNickname] = useState('');
  const [guest, setGuest] = useState<Guest>();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const guestId = query.get("guestId");

  if (!guestId) {
    return <>{t('noGuestSpecified')}</>
  }

  useEffect(() => {
    (async () => {
      const data = await fetch(`${baseUrl}/guest/${guestId}`);
      const json = await data.json();
      if (!data.ok) {
        setHasLoaded(true);
        return;
      }

      setGuest(json);
      setHasLoaded(true);
    })();
  }, [])

  const flipCard = () => {
    setIsFlipped(true);
    setTimeout(() => {
      navigate(`/information?guestId=${guestId}`);
    }, 500);
  }

  if (hasLoaded && !guest) {
    return <>{t('guestNotFound', { id: guestId })}</>
  }

  if (!guest) {
    return <></>
  }

  const updateGuestNickname = async () => {
    const response = await fetch(`${baseUrl}/guest/${guest.guestId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ nickname })
    })

    const json = await response.json();

    if (!response.ok) {
      alert('Brevdua er litt vanskelig og liker ikke denne emojien (eller så har noen andre valgt den), vennligst velg en annen!')
      return;
    }

    window.location.reload();
  }

  if (!guest.nickname) {
    return (
      <div className="page-container">
        <div>
          <p>Hei, {guest.firstName.split(' ')[0]}! Det er en brevdue på vei til deg.</p>
          <p>Han må vite din favoritt-emoji 🕊️ Hold den hemmelig 🤫</p>
          <p>
            <div><small>PS: Denne nettsiden fungerer best på PC-skjerm</small></div>
            <div><small>PPS: Klikk på feltet og bruk Windows-knappen + "." for å åpne emoji-valg</small></div>
          </p>
          <input type="text" placeholder="Eksempel: 🎁" value={nickname} onChange={e => setNickname(e.target.value)}></input><br/>
          <button className="mt-2" type="button" onClick={updateGuestNickname}>Bekreft favoritt-emoji</button>
        </div>
      </div>
    )
  }

  if (guest.highscore === 0) {
    return <BirdGame guest={guest} />
  }

  return (
    <div className="page-container">
      <Card guest={guest} isFlipped={isFlipped} />
      <div className="info-button-container">
        <button className={isFlipped ? "opacity-0" : ""} onClick={flipCard}>Svar / informasjon</button>
        <a className={isFlipped ? "opacity-0" : ""} href={`delivery?guestId=${guest.guestId}`}>
          <button>{t('replayGame')}</button>
        </a>
      </div>
    </div>
  );
};
