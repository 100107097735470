import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Answer, Guest } from "../../models/Guest";
import { constants } from '../../utils/constants';
import './Card.css';

type CardProps = {
    isFlipped: Boolean;
    openResponseCard?: any;
    guest: Guest;
}

export const Card = ({ isFlipped, openResponseCard, guest }: CardProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="card-container">
            <div className={"card " + (isFlipped ? "card-flipped" : "")}>
                <div className="card-front">
                    <div className="deco">
                        <img src="./img/decoration.png" />
                    </div>
                    <div className="card-content">
                        <h1>{t('brideAndGroomNames')}</h1>
                        <p>har gleden av å invitere deg <span className="key-info">{guest.firstName} {guest.lastName}</span> til vårt bryllup
                            den <span className="key-info">{constants.dateStringShort}</span> på&nbsp;
                            <span className="key-info">{constants.locationName}</span>.
                        </p>
                    </div>
                </div>
                <div className="card-back">
                    <div className="deco">
                        <img src="./img/decoration.png" />
                    </div>
                    <div className="card-content">
                        {guest.answer === null || guest.answer === Answer.Unanswered ? <></> : <img className="w-full" src={`./img/guests/${guest.guestId}.jpg`} alt="Bilde" />}

                        <h1>{guest.firstName}</h1>
                        <div>Vi ønsker deg velkommen til vårt bryllup. Scroll ned for flere detaljer.</div>

                        <h2>{t('when')}</h2>
                        <div>{constants.dateString}</div>
                        <div>Vielse fredag kl. 12 på Rådhuset</div>
                        <small>Vielse for de som ønsker å komme, men varer bare i 15 minutter. Ikke noe opplegg etterpå.</small>

                        <h2>Timeplan</h2>
                        <div>
                            <div><b>16:30:</b> Velkomstdrink + bilder</div>
                            <div><b>18:00:</b> Middag</div>
                            <div><b>19:30:</b> Aktiviteter</div>
                            <div><b>20:00:</b> Kakeskjæring/dessert</div>
                            <div><b>23:00:</b> Uteområdet stenger</div>
                            <div><b>02:00:</b> Lokalet stenget</div>
                        </div>

                        <h2>{t('where')}</h2>
                        <div>
                            {constants.locationName}, {constants.cityName} (<a href="https://goo.gl/maps/byGcWhdP9WRJENks5" target="_blank">{t('map')}</a>)
                        </div>

                        <h2>Antrekk</h2>
                        <div>Sommerlig pent</div>

                        <h2>Gaver</h2>
                        <div>Vi har alt vi trenger, men tar gjerne en liten donasjon til vår bryllupsreise til Kenya.</div>
                        <div>Penger kan enten sendes til kontonummer 1224.66.89650, eller Vippses til 41386121. Tusen takk!</div>

                        <h2>Bilder</h2>
                        <div>Vi vil laste opp bilder til <a href="https://photos.app.goo.gl/n39u2XKC7CkXpNSNA">Google Drive</a>, og håper andre vil gjøre det samme!</div>

                        <p>Håper du kan komme! Vennligst svar på denne invitasjonen så fort du vet. Mer informasjon vil komme etterhvert.</p>

                        <div>
                            <div>Med vennlig hilsen</div>
                            <div>{constants.brideName} og {constants.groomName}</div>
                        </div>

                        <div className="text-center">
                            <button onClick={() => openResponseCard()}>{guest.answer === null || guest.answer === Answer.Unanswered ? t('answer') : 'Endre svar'}</button>
                        </div>

                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    )
}