import React, { ReactElement, useState, useEffect } from "react";
import { Answer, Guest } from "../../models/Guest";
import { useQuery } from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import './Admin.css';
import { baseUrl } from "../../utils/baseUrl";
import { GuestInfo } from "./GuestInfo";

export const ListGuestsPage = (): ReactElement => {
  const query = useQuery();
  const [guests, setGuests] = useState<Guest[]>([]);
  const { t } = useTranslation();

  const auth = query.get("auth");

  if (!auth) {
    return <>{t('admin.addAuthInUrl')}</>
  }

  useEffect(() => {
    (async () => {
      const response = await fetch(`${baseUrl}/admin/guest`, {
        method: 'GET',
        headers: {
          'Authorization': auth,
          'Content-Type': 'application/json'
        },  
      });
      const guests = await response.json();
      setGuests(guests);
    })();
  }, [])

  const copyEmails = () => {
    const allEmailAddresses = guests.filter(g => g.answer !== Answer.No).map(g => g.email);
    const emailsString = allEmailAddresses.join(';');

    navigator.clipboard.writeText(emailsString);

    alert(`Copied ${allEmailAddresses.length} email addresses to clipboard.`);
  }

  const deleteGuest = async (guest: Guest) => {
    if (!confirm(t('admin.confirmGuestDeletion', { name: guest.firstName }))) {
      return;
    }

    const response = await fetch(`${baseUrl}/admin/guest/${guest.guestId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': auth,
        'Content-Type': 'application/json'
      },  
    });

    if (!response.ok) {
      alert(response);
    }

    setGuests(guests.filter(item => item.guestId !== guest.guestId));
  }

  return (
    <div className="admin-container">
      <a href={`/admin?auth=${auth}`}>{t('back')}</a>
      <h1>{t('administration')}</h1>

      <div className="d-flex flex-row gap-2">
        <a href={`/admin/guests/add?auth=${auth}`}><button>{t('admin.addGuest')}</button></a>
        <button onClick={copyEmails}>{t('admin.copyEmails')}</button>
      </div>

      <div className="mt-2">
        <div><strong>Total invited:</strong> {guests.length}</div>
      </div>

      <h2>{t('answerYes')} ({guests.filter(g => g.answer === Answer.Yes).length})</h2>
      <div className="guests-list">
        {guests.filter(g => g.answer === Answer.Yes).map(g => <GuestInfo guest={g} deleteClicked={deleteGuest} />)}
      </div>

      <h2>{t('answerMaybe')} ({guests.filter(g => g.answer === Answer.Maybe).length})</h2>
      <div className="guests-list">
        {guests.filter(g => g.answer === Answer.Maybe).map(g => <GuestInfo guest={g} deleteClicked={deleteGuest} />)}
      </div>

      <h2>{t('answerNo')} ({guests.filter(g => g.answer === Answer.No).length})</h2>
      <div className="guests-list">
        {guests.filter(g => g.answer === Answer.No).map(g => <GuestInfo guest={g} deleteClicked={deleteGuest} />)}
      </div>

      <h2>{t('notAnswered')} ({guests.filter(g => g.answer === null || g.answer === Answer.Unanswered).length})</h2>
      <div className="guests-list">
        {guests.filter(g => g.answer === null || g.answer === Answer.Unanswered).map(g => <GuestInfo guest={g} deleteClicked={deleteGuest} />)}
      </div>
    </div>
  );
};
